import styled from "styled-components"
import React from "react"

const White = styled.section`
  background-color: #eff1ef;
  height: 100%;
  width: 100%;
  color: #455260;
  font-weight: 300;
  position: relative;
  display: inline-block;

  @media (min-width: 992px) {
    padding: 50px 90px;
  }

  & h2 {
    font-size: 28px;
    color: #25333d;
    font-weight: 300;
    margin-top: 30px;
  }

  & a {
    color: #455260;
    text-decoration: underline;
    text-decoration-color: #c6af6d;
    text-decoration-thickness: 2px;
  }

  & p {
    font-size: 18px;
    max-width: 85ch;
    line-height: 1.5em;
  }

  @media (min-width: 992px) {
    & p,
    ul {
      padding-left: 90px;
    }
  }

  @media (max-width: 990px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const Row = styled.div`
  @media (max-width: 992px) {
    width: 85%;
    margin: auto;
  }
`

const WhitePageContent = ({ children }) => {
  return (
    <White>
      <Row>{children}</Row>
    </White>
  )
}

export default WhitePageContent
