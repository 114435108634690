import React from "react"
import MFsquaresBlueTopIcon from "../../images/MFsquaresBluetop.svg"
import styled from "styled-components"

const MFsquaresBlueTop = () => {
  return (
    <MFSquaresWrapper>
      <MFSquaresBlueTop
        src={MFsquaresBlueTopIcon}
        alt="Marketingfabrikken squares"
      />
    </MFSquaresWrapper>
  )
}

export default MFsquaresBlueTop

const MFSquaresBlueTop = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  margin-bottom: 0;
`

const MFSquaresWrapper = styled.div`
  @media (max-width: 1280px) {
    padding-bottom: 75px;
  }
`
