import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../../components/layout"
import BluePageContent from "../../components/StyledElements/bluePageContent"
import WhitePageContent from "../../components/StyledElements/whitePageContent"
import parse from "html-react-parser"
import { ContactForm } from "../../components/contactform/contactform"
import { GatsbyImage } from "gatsby-plugin-image"
import MFsquaresBlueTop from "../../components/StyledElements/mfsquaresBlueTop"
import MFsquaresWhiteBottom from "../../components/StyledElements/mfsquaresWhiteBottom"
import Seo from "gatsby-plugin-wpgraphql-seo"

const WpPage = ({ data }) => {
  const { wpPage } = data

  return (
    <>
      <Seo post={wpPage} />
      <Layout>
        <BluePageContent>
          <h1>{wpPage.title}</h1>
          <TwoGrid>
            <div>
              <Heading>{wpPage.ACFundersider.herohead.heroHeading}</Heading>
              <Text>{parse(`${wpPage.ACFundersider.herohead.heroText}`)}</Text>
            </div>

            <ul>
              <Specialiserer>Vi udnytter synergier mellem</Specialiserer>
              {wpPage.ACFundersider.herohead.bulletlist.map(
                (bulletlist, id) => (
                  <ListItems key={id}>
                    {parse(`${bulletlist.bullettext}`)}
                  </ListItems>
                )
              )}
            </ul>
          </TwoGrid>
          <ContactForm />
          <MFsquaresWhiteBottom />
        </BluePageContent>
        <WhitePageContent>
          <MFsquaresBlueTop />
          {parse(`${wpPage.ACFundersider.whiteSection}`)}
        </WhitePageContent>
        <EkspertGrid>
          <GatsbyImage
            image={
              wpPage.ACFundersider.eksperten.ekstpertimg.localFile
                .childImageSharp.gatsbyImageData
            }
            alt={wpPage.ACFundersider.eksperten.ekstpertimg.altText}
          />
          <EkspertDiv>
            {parse(`${wpPage.ACFundersider.eksperten.eksperttxt}`)}
          </EkspertDiv>
        </EkspertGrid>
        <BluePageContent>
          {parse(`${wpPage.ACFundersider.bottomSection}`)}
        </BluePageContent>
      </Layout>
    </>
  )
}

export default WpPage

export const query = graphql`
  query PageById($id: String) {
    wpPage(id: { eq: $id }) {
      __typename
      id
      uri
      title
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      ACFundersider {
        whiteSection
        bottomSection
        herohead {
          bulletlist {
            bullettext
          }
          heroHeading
          heroText
        }
        eksperten {
          eksperttxt
          ekstpertimg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  height: 480
                )
              }
            }
          }
        }
      }
    }
  }
`

const TwoGrid = styled.div`
  @media (min-width: 993px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 8rem;
    padding: 50px 0px;
    position: relative;
  }
`

const EkspertGrid = styled.div`
  @media (min-width: 993px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    padding: 0px;
    position: relative;
    background-color: #25333d;
    color: #eff1ef;
    font-weight: 400;
  }
`

const ListItems = styled.li`
  list-style-type: square;
  color: #edd9af;
  font-size: 18px;
  font-weight: 300;
  text-decoration: underline;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

const Heading = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: #eff1ef;
  max-width: 600px;
  margin-top: 0px !important;
`

const Specialiserer = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: #eff1ef;
  max-width: 600px;
  margin-top: 0px !important;
`

const Text = styled.div`
  font-weight: 300;
  color: #eff1ef;
  & p {
    max-width: 600px;
    font-size: 18px;
    font-weight: 300;
    padding-left: 0 !important;
  }
`

const EkspertDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 6em;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.75em;
  background-color: #25333d;
  color: #eff1ef;
  @media (max-width: 993px) {
    padding: 20px;
  }
`
